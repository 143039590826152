import Vue from 'vue'
import App from './App.vue'
import store from './store'
import vuetify from './plugins/vuetify'
//import Echo from 'laravel-echo'

Vue.config.productionTip = false

import VueMask from 'v-mask'
Vue.use(VueMask);

import VueGeolocation from 'vue-browser-geolocation';
Vue.use(VueGeolocation);

Vue.prototype.$eventBus = new Vue(); // 1способ

//import Error401 from './plugins/Error401'
//Vue.use(Error401)

import VueConfirmDialog from 'vue-confirm-dialog'

Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)


import router from './router'

router.beforeEach((to, from, next) => {

  // тут мы сморим если маршруты закрыты в роутере то проверяем тухлость токена
  if(to.matched.some(record => record.meta.requiresAuth)) {
    // если нет токена выкидываем
    if (localStorage.getItem('UserData') == null) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    }

    
    /// если токен протух выкидываем
    // let tokenTime = JSON.parse(localStorage.getItem('UserData')).payload.exp;
    // let timeNow = Math.round(Date.now() / 1000);
    // if(timeNow > tokenTime){
    //   alert('Заколчилось время сессии');
    //   store.commit('UpdateUserData');
    //   localStorage.removeItem('UserData');
    //   store.commit('DepartmentInfo');
    //   localStorage.removeItem('DepartmentInfo');
    //   next({
    //     path: '/login',
    //     params: { nextUrl: to.fullPath }
    //   })
    // }


   // window.Pusher = require('pusher-js')

    //let token = JSON.parse(localStorage.getItem('UserData')).access_token;


          // if (localStorage.getItem('UserData') != null) {

          //   window.Pusher = require('pusher-js')

          //   let token = JSON.parse(localStorage.getItem('UserData')).access_token;

          //   window.Echo = new Echo({
          //     broadcaster: 'pusher',
          //     key: '123456',
          //     cluster: '123456',
          //     wsHost: (window.location.hostname === 'localhost11') ? window.location.hostname : 'service.metizi.net',
          //     authEndpoint: (window.location.hostname === 'localhost11') ? window.location.protocol+'//localhost:8001/broadcasting/auth' : window.location.protocol+'//service.metizi.net/api/websocket/broadcasting/auth',
          //     //authEndpoint: window.location.protocol+'//service.metizi.net/api/websocket/broadcasting/auth',
          //     encrypted: true,
          //     wsPort: (window.location.hostname === 'localhost11') ? '6001' : '80',
          //     wssPort: (window.location.hostname === 'localhost11') ? '6001' : '443',
          //     disableStats: true,
          //     forceTLS: false,
          //     enabledTransports: ['ws', 'wss'],
          //     auth: {
          //       headers: {
          //         authorization: 'Bearer ' + token,
          //       }
          //     }
          //   })

          //   window.Echo.connector.pusher.connection.bind('connecting', (payload) => {
          //     //this.WebSocketState = {'color': 'yellow', 'state': 'connecting...'}
          //     console.log('connecting...', payload);
          //   });

          //   window.Echo.connector.pusher.connection.bind('connected', (payload) => {
          //     //this.WebSocketState = {'color': 'white', 'state': 'connected!'}
          //     console.log('connected!', payload);
          //   });

          //   window.Echo.connector.pusher.connection.bind('unavailable', (payload) => {
          //     //this.WebSocketState = {'color': 'red', 'state': 'unavailable'}
          //     console.log('unavailable', payload);
          //   });

          //   window.Echo.connector.pusher.connection.bind('failed', (payload) => {
          //     //this.WebSocketState = {'color': 'red', 'state': 'failed'}
          //     console.log('failed', payload);
          //   });

          //   window.Echo.connector.pusher.connection.bind('disconnected', (payload) => {
          //     //this.WebSocketState = {'color': 'red', 'state': 'disconnected'}
          //     console.log('disconnected', payload);
          //   });


          // }

    next()


  }else {
    next()
  }
})


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
