import Vue from 'vue'
import VueRouter from 'vue-router'

import IndexView from '../views/IndexView.vue'
//import UsersView from '../views/UsersView.vue'
//import QRCodeView from '../views/QRCodeView.vue'
//import LoginView from '../views/LoginView.vue'
import LogoutView from '../views/LogoutView.vue'
//import AboutView from '../views/AboutView.vue'
//import GetLog from '../views/GetLog.vue'
import ChangePassView from "@/views/ChangePassView.vue"


//import RequestView from '@/views/RequestView'
//import NotFound from '../views/NotFound.vue'


Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'index',
    component: IndexView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/users',
    name: 'users',
    //component: UsersView,
    component: () => import('@/views/UsersView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/user/:login',
    name: 'user',
    //component: UsersView,
    component: () => import('@/views/UserCard.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/adduser',
    name: 'adduser',
    //component: UsersView,
    component: () => import('@/views/UserCard.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/request',
    name: 'request',
    //component: RequestView,
    component: () => import('@/views/RequestView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/message',
    name: 'message',
    //component: RequestView,
    component: () => import('@/views/ChatWindow.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/room/:roomId',
    name: 'room',
    //component: RequestView,
    component: () => import('@/views/ChatWindow.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/services',
    name: 'services',
    //component: RequestView,
    component: () => import('@/views/ServicesView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/phonebook',
    name: 'phonebook',
    //component: RequestView,
    component: () => import('@/views/PhoneBookView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  // {
  //   path: '/user/:id',
  //   name: 'user',
  //   component: UserProfile,
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  // {
  //   path: '/qrcode',
  //   name: 'qrcode',
  //   component: QRCodeView,
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  // {   path: "*",
  //     component: NotFound,
  //     meta: {
  //       requiresAuth: true
  //     }
  // },
  // {   path: "/login",
  //     //component: LoginView
  //     component: () => import('../views/LoginView.vue')
  // },
  {   path: "/changepass",
    component: ChangePassView
  },
  {   path: "/logout",
      component: LogoutView
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  mode: "history",
  routes
})

export default router
