<template>

<v-card
    v-if="$store.state.MyNewMessage > 0"
    class="mx-auto mb-3"
  >

    <v-card-title class="pa-2">
      <!-- <v-icon>
        mdi-email-alert-outline
      </v-icon> -->
      У вас есть новые сообщения {{ $store.state.MyNewMessage }}
    </v-card-title>

    <!-- <v-card-subtitle class="pa-2">

          У вас есть новые сообщения {{ $store.state.MyNewMessage }}


    </v-card-subtitle> -->

    <v-card-actions class="pa-0">
      <v-btn
        small
        color="orange lighten-2"
        text
        @click="GoToMessage"
      >
        Показать
      </v-btn>

      <v-spacer></v-spacer>


    </v-card-actions>


  </v-card>
  
</template>
  
<script>


  export default {

      // data () {
      //   return {
      //     MewMessage: 0,
      //   }
      // },

      methods:{

        getNewMessage(){

          let json = {
            'pathUrl': this.$store.state.urlPathAuth,  /// путь
            'method': 'get',
            'alert': true, 
            'url': 'newmessage',
            'loading': false,
            //'update': 'UpdateGetLog',
            'postData': { }
          }
          this.$store.dispatch('axiosData', json)
          .then(response => {
            this.$store.state.MyNewMessage = response.data
          })
        },

        GoToMessage(){

          this.$router.push('/message')
        }

      },

      mounted(){

        this.getNewMessage()
      }
  }



</script>