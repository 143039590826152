<template>
<div>
  Выходим...
</div>
</template>
<script>
import axios from 'axios';

export default {


  mounted(){


    if(this.$store.state.UserData != undefined){

          axios.post(this.$store.state.urlPathAuth + '/logout', {
            dids: '0'
          }, { headers: { 'Authorization': 'Bearer ' + this.$store.state.UserData.access_token }})
            .then(response => {
              console.log(response.data);
            })
      }

      this.$store.commit('UpdateUserData');
      localStorage.removeItem('UserData');
      //this.$store.commit('UpdateDepartmentInfo');
      //localStorage.removeItem('DepartmentInfo');

      window.location.href = './login';
      //this.$router.push('login');

  }

};
</script>
