<template>
  <div>

    <v-container style="max-width: 1000px;">
    <v-card>
      <v-responsive >
        <v-card-text>

          <v-form
            ref="form"
            :rules="rule"
            v-model="addDevValid"
            lazy-validation
            class="text-center"
          >

            Придумайте свой пароль
            <br><br>
            пароль должен состоять не менее чем из 4 символов и не более чем из 20 символов, хотя бы одна заглавная буква и цифра [a-z, A-Z, 0-9]
            <v-text-field
                v-model="password1"
                :rules="rule"
                label="Пароль"
                type="password"
            ></v-text-field>

<!-- :rules="rule" -->
          <v-text-field
            v-model="password2"
            :rules="rule"
            label="Повторите пароль"
            type="password"
          ></v-text-field>


          <v-btn
            class="primary"
            @click="PassToApp"
            :loading="GlobaLoading"
            :disabled="!addDevValid"
            depressed>
            Установить пароль
          </v-btn>

    </v-form>

        </v-card-text>
      </v-responsive>
    </v-card>
    </v-container>


  </div>



</template>
<script>
//import axios from 'axios';
import { mapState, mapMutations } from 'vuex';

export default {

  name: 'LoginView',

  data() {

    return {
      show1: false,
      show2: false,
      PassDisabled: false,
      firstEnter: false,
      login: null,
      password1: null,
      password2: null,
      //loadingBut: false,
      addDevValid: true,

      rule: [
        value => !!value || 'Надо заполнить.',
        value => (value && value.length >= 4) || 'Минимум 4 символа',
        value => /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/.test(value) || 'Пароль должен содержать как минимум строчную букву, одну цифру и одну прописную букву',
      ],
    }
  },
  computed: {
       ...mapState([
        'UserData',
        'urlPathAuth',
        'GlobaLoading'
      ])
  },
  methods : {
    ...mapMutations([
      'UpdateUserData',
      'UpdateGlobaLoading',
    ]),




    PassToApp(){


      if(!this.$refs.form.validate()){
        this.UpdateGlobaLoading(false);
        //this.loadingBut = false;
      }

      if(this.password1 !== null && this.password2 !== null && this.password1 === this.password2) {

        this.UpdateGlobaLoading(true);
        //this.loadingBut = true;


        let json = {
          'pathUrl': this.$store.state.urlPathAuth,  /// путь
          'method': 'post',
          'alert': true, 
          'url': 'changepassuser',
          'loading': true,
          //'update': 'UpdateListService',
           'postData': {
              'id': this.UserData.user.id,
              'login': this.UserData.user.login,
              'password': this.password1,
              'password2': this.password2,
              'fio': this.UserData.user.fio,
              //email: this.UserData.user.em,
              //tel: this.tel,
              'status': this.UserData.user.status,
              'department': this.UserData.user.department,
              'rolemain': this.UserData.user.rolemain,
              'needChangePass': 0
           }
        }
        this.$store.dispatch('axiosData', json)
        .then(response => {

          if (response.data.success === 1) {

            this.$confirm(
                {
                  title: 'Пароль установлен',
                  message: 'Для вступления в силу изменении вашей учетной записи необходимо заново войти в сервис',
                  button: {
                    yes: 'OK'
                  },
                  callback: confirm => {
                    if (confirm) {
                      this.$store.commit('UpdateUserData');
                      localStorage.removeItem('UserData');
                      this.$store.commit('UpdateDepartmentInfo');
                      localStorage.removeItem('DepartmentInfo');
                      //this.$router.push('login');
                      window.location.href = '/login';
                    }
                  }
                })

          } 

        })

        // axios.post(this.urlPathAuth + '/modifyuser', {
        //   id: this.UserData.user.id,
        //   login: this.UserData.user.login,
        //   password: this.password,
        //   password2: this.password2,
        //   fio: this.UserData.user.fio,
        //   //email: this.UserData.user.em,
        //   //tel: this.tel,
        //   status: this.UserData.user.status,
        //   department: this.UserData.user.department,
        //   rolemain: this.UserData.user.rolemain,
        //   needChangePass: 0
        // },{ headers: { 'Authorization': 'Bearer '+this.UserData.access_token }})
        // .then(response => {

        //   if (response.data.success === 1) {

        //     this.$confirm(
        //         {
        //           title: 'Пароль установлен',
        //           message: 'Для вступления в силу изменении вашей учетной записи необходимо заново войти в сервис',
        //           button: {
        //             yes: 'OK'
        //           },
        //           callback: confirm => {
        //             if (confirm) {
        //               this.$store.commit('UpdateUserData');
        //               localStorage.removeItem('UserData');
        //               this.$store.commit('UpdateDepartmentInfo');
        //               localStorage.removeItem('DepartmentInfo');
        //               //this.$router.push('login');
        //               window.location.href = '/login';
        //             }
        //           }
        //         })

        //     //localStorage.setItem('UserData',  JSON.stringify(response.data));
        //     //this.UpdateUserData(response.data);
        //     // this.UpdateGlobaLoading(false);
        //     // this.loadingBut = false;
        //     //
        //     // this.$store.commit('UpdateUserData');
        //     // localStorage.removeItem('UserData');
        //     // //this.$store.commit('UpdateDepartmentInfo');
        //     // //localStorage.removeItem('DepartmentInfo');
        //     // //this.$router.push('login');
        //     //
        //     // window.location.href = '/'
        //   } else{

        //     alert('Ошибка')
        //   }

        // })
        // .catch(error => {

        //   if (error.response.status == 401) {



        //   } else {
        //     alert(error);
        //   }
        //   this.UpdateGlobaLoading(false);
        //   this.loadingBut = false;
        // });

      }else{

        this.$confirm(
          {
            title: 'Внимание 😨',
            message: 'Пароли не совпадают',
            button: {
              yes: 'OK'
            },
          })

      }

    },

  },
  mounted(){
        //     this.$confirm(
        //         {
        //           title: 'Пароль установлен',
        //           message: 'Для вступления в силу изменении вашей учетной записи необходимо заново войти в сервис',
        //           button: {
        //             yes: 'OK'
        //           },

  }

};
</script>
