<template>

  <v-app v-if="auth">

    <!-- Это когда пользователь заререген -->
      <AuthUser/>

     <vFooter/>
  </v-app>
  <v-app v-else>

    <vue-confirm-dialog></vue-confirm-dialog>

    <!-- это на страницу логина -->
    <NotAuthUser/>

  </v-app>
</template>

<script>


export default {

  components: {
    'AuthUser': () => import('@/components/AuthUser.vue'),
    'NotAuthUser': () => import('@/components/NotAuthUser.vue')
  },
  data: () => ({
    auth: true

  }),
  // methods:{

  // },
  mounted(){

    // если нет токена выкидываем
    if (localStorage.getItem('UserData') == null) {
      this.auth = false
    }
    else {
      /// если токен протух выкидываем
      let tokenTime = JSON.parse(localStorage.getItem('UserData')).payload.exp;
      let timeNow = Math.round(Date.now() / 1000);
      if(timeNow > tokenTime){

        this.$confirm(
          {
            title: 'Внимание 🤚',
            message: 'Заколчилось время сессии',
            button: {
              yes: 'OK'
            },
          })

        //alert('Заколчилось время сессии');
        this.$store.commit('UpdateUserData');
        localStorage.removeItem('UserData');

        this.auth = false
      }
    }

    /// узнаем свои данные и сверяем подразделение

    // let json = {
    //     'pathUrl': this.$store.state.urlPathAuth,  /// путь
    //     'method': 'post',
    //     'alert': false, 
    //     'url': 'me',
    //     'loading': false,
    //     //'update': 'UpdateListService',
    //     'postData': {}
    //   }
    //   this.$store.dispatch('axiosData', json)
    //     .then(response => {

    //       if(response.data.department !== this.$store.state.UserData.department.id){

    //         alert('Судать вы дно!')
    //       }
    //       //console.log(response.data);
    //     })




    //this.getConfig();
    //this.getPermToEnterInService();
    //this.getDepartmentInfo(this.$store.state.UserData.user.department);
  }
};
</script>
