import Vue from 'vue'
import Vuex from 'vuex'
//import LoginView from '../views/LoginView'
//import GitVers from "!raw-loader!../../.git/COMMIT_EDITMSG";

Vue.use(Vuex)

import axios from 'axios';


//import localStorage from 'nativescript-localstorage';

export default new Vuex.Store({
  state: {
    UserData: JSON.parse(localStorage.getItem("UserData")),
    //DepartmentInfo: JSON.parse(localStorage.getItem("DepartmentInfo")),
    RoleData : [],
    GlobaLoading: false,
    ListService: [],

    // это для веб сокета
    UserOnline:[],

    PassWin: false,
    PassJson: null,


    urlPathAuth: (window.location.hostname == 'localhost') ? 'http://127.0.0.1:8000/auth/v1' : window.location.protocol+'//auth.metizi.net/auth/v1',
    //urlPath: (window.location.hostname == 'localhost') ? 'http://127.0.0.1:8000/auth/v1' : window.location.protocol+'//auth.metizi.net/auth/v1',
    //urlPathAuth: window.location.protocol+'//auth.metizi.net/auth/v1',
    //urlPath: window.location.protocol+'//auth.metizi.net/auth/v1',
    //urlPathAuth: 'https://auth.metizi.net/auth/v1',
    urlPathQR: 'https://service.metizi.net/',

    urlPathPhoto:  (window.location.hostname == 'localhost11') ? 'http://127.0.0.1:8000' : window.location.protocol+'//auth.metizi.net',
    //GitVers: GitVers,
    //urlPathAuth: 'http://172.25.0.108:8000/auth/v1',
    //urlPathQR: 'http://192.168.88.13:8080/',
    //urlPathAuth: 'http://192.168.88.16:8001/auth/v1',

//    urlPathQR: 'http://192.168.88.16:8000',
    UsersList: [],
    DialogUserProfile: false,
    DialogUserData: [],
    DialogUserPermissionWin: false,
    DialogUserPermission: [],
    ServiceList: [],
    RoleList: [],
    DictPermitForCurrentUser: [],
    RolesForSelectUserList: [],
    PermDepartments: [],
    GetLog:[],
    FirstEnter: {
      'icon': 'mdi-account-plus',
      'title': 'Добавить пользователя',
      'adduser': true,
      'needChangePass': true
    },

    ServicesListForAdmin:[],
    SelectedPermision:[],

    AdminServiceInfo: null,

    PhotoUserOpen: {
      openwin: false,
      pathphoto: ''
    },


    //ToggleItem: 0,
    LoadingUsers: false,

    // ListUsersMes: [],
    // ListUsersMesTmp: [],
   // ListDepartmentsMes: [],
    //ListRolesMes: [],
    //ListLastChatMes: [],

    
    SendMessListUser: [],
    RecipientSearch: [],
    ChatWinData: [],

    Rooms: [],
    RoomsForSearch: [],
    RoomOpen: null,
    SelectedRoom: 0,
    RoomEditWin: { 'state': false, 'data': null },
    InfoRoomWin: { 'state': false, 'data': null },
    AttachWin: { 'state': false, 'data': null },

    UsersInRoomOnline: [],

    MyNewMessage: 0,

    ShowCheckBox: false,
    PrintMessInChat: '...',

    ReplyMessage: null,

    PhoneBook: JSON.parse(localStorage.getItem("PhoneBook")),
    AddEditContactWin: false,

    ContactAddEdit: { 
      'id': null,
      'type': 1,
      'department_id': null,
      'user_id': null, 
      'contact_type_id': 0,
      'data': null,
      'comment': '',
      'permission': 0
    },

    ///isMobile: (Vue.prototype.$vuetify.breakpoint.width > 960) ? false : true,
  
    ExitApp: false,

  },
  getters: {
    UsersListCount (state) {
      return state.UsersList.length
    },
    GetLogCount (state) {
      return state.GetLog.length
    },
  },
  mutations: {


    UpdateUserData (state, UserData) {
      state.UserData = UserData
    },
    UpdateListService (state, data) {
      state.ListService = data
    },
    UpdateRoleData (state, RoleData) {
      state.RoleData = RoleData
    },
    UpdateGlobaLoading (state, GlobaLoading) {
      state.GlobaLoading = GlobaLoading
    },
    UpdateUsersList (state, UsersList) {
      state.UsersList = UsersList
    },
    UpdateDialogUserProfile (state, payload) {
      state.DialogUserProfile = payload.bool,
      state.DialogUserData = payload.value
    },
    UpdateDialogUserPermission (state, payload) {
      state.DialogUserPermissionWin = payload.bool,
      state.DialogUserPermission = payload.value
    },
    UpdateServiceList (state, ServiceList) {
      state.ServiceList = ServiceList
    },
    UpdateRoleList (state, RoleList) {
      state.RoleList = RoleList
    },
    UpdateDictPermitForCurrentUser (state, DictPermitForCurrentUser) {
      state.DictPermitForCurrentUser = DictPermitForCurrentUser
    },
    UpdateRolesForSelectUserList (state, pRolesForSelectUserList) {
      state.RolesForSelectUserList = pRolesForSelectUserList
    },
    UpdatePermDepartments (state, PermDepartments) {
      state.PermDepartments = PermDepartments
    },
    UpdateGetLog (state, data) {
      state.GetLog = data
    },
    UpdateExitApp (state, data) {
      state.ExitApp = data
    },
    UpdateServicesListForAdmin (state, data) {
      state.ServicesListForAdmin = data
    },
    UpdateAdminServiceInfo (state, data) {
      state.AdminServiceInfo = data
    },
    UpdateFirstEnter (state, data) {
      state.FirstEnter = data
    },
    UpdateSelectedPermision (state, data) {
      state.SelectedPermision = data
    },
    UpdateUserOnline (state, data) {
      state.UserOnline = data
    },
    UpdatePhotoUserOpen (state, data) {
      state.PhotoUserOpen = data
    }, 

    // UpdateListUsersMes (state, data) {
    //   state.ListUsersMes = data.users
    //   state.ListUsersMesTmp = data.lastchat
    //   state.ListLastChatMes = data.lastchat
    //   state.ListDepartmentsMes = data.departments
    //   state.ListRolesMes = data.roles
    // }, 
    UpdateListItemsMes (state, data) {
      state.ListItemsMes = data
      //state.RecipientSearch = data
    }, 

    UpdateSendMessListUser (state, data) {
      state.SendMessListUser = data
    }, 
  
    UpdateChatWinData (state, data) {
      state.ChatWinData = data
    }, 

    UpdateRooms (state, data) {
      state.Rooms = data
      state.RoomsForSearch = data
    }, 

    UpdatePrintMessInChat (state, data) {
      state.PrintMessInChat = data
    }, 

    UpdateRoomOpen (state, data) {
      state.RoomOpen = data
    }, 

    UpdatePhoneBook (state, data) {
      state.PhoneBook = data
    }, 

    
    
  },
  actions: {

    OnlineUserInRoomList({ state }) {

      //// это для чата кто в онлайн
      const ArrUserOnline = []
      const ArrUserOnlineTmp = state.UserOnline
      
        ArrUserOnlineTmp.forEach((elem) => {
          ArrUserOnline[elem.id] = elem.id
        });
      
             for (let t = 0; t < state.Rooms.length; t++) {
                for (let r = 0; r < state.Rooms[t].users.length; r++) {                    
                  if (state.Rooms[t].users[r]._id === ArrUserOnline[state.Rooms[t].users[r]._id]) {
                        state.Rooms[t].users[r].status.state = 'online'
                  }
                }
              }

    },

    /// универсальный метод post get put delete
    /// пример запроса

    // let json = {
    //   'pathUrl': 'urlPathUpaddr',        /// это не доратал
    //   'method': 'post',                  /// метод
    //   'alert': true,                     /// показывать ответ или тихо работать
    //   'url': 'getgoods',                 /// url api
    //   'update': 'UpdateListUpAddress',   /// запустить mutations
    //   'loading': true,                   /// показывать загрузку
    //   'postData': {                      /// параметры
    //     'curshop': this.$store.state.UserData.department.sm,
    //   }
    // }
    // this.$store.dispatch('axiosData', json) /// вызов

    axiosData({ state, commit, dispatch }, param) {

      /// если мы вышли из приложения то не выполняем
      if(state.ExitApp === true){
        alert('Вы вышли из приложения')
        commit('UpdateExitApp', true);
      }else{

      if(param.loading) {
          commit('UpdateGlobaLoading', true);
      }

      
          return new Promise((resolve, reject) => {

            const sendparams = {
              timeout: 15000,
              method: param.method,
              url: param.pathUrl + '/'+ param.url,
              data: param.postData,
              headers: { 
                'Authorization': 'Bearer ' + state.UserData.access_token
              }
            }

            if(param.method == 'get'){
              sendparams['params'] = param.postData
            }else{
              sendparams['data'] = param.postData
            }

            axios(sendparams)

              // axios({
              //   method: param.method,
              //   url: param.pathUrl + '/'+ param.url,
              //   params: param.postData,
              //   headers: { 'Authorization': 'Bearer ' + state.UserData.access_token }
              // })
                  .then(response => {
          
                      resolve(response)
          
                      if(response.data.error){  /// если error

                          console.log(response.data.error);

                          if(param.alert) {
                              alert('Внимание! ' + response.data.error)
                           }

                      }else{
          
                          if(param.update) {
                              commit(param.update, response.data);
                          }
          
                          //console.log(response);
                          //alert(response.data)
                      }
          
                      if(param.loading) {
                          commit('UpdateGlobaLoading', false);
                      }
                  })
                  .catch(error => {
          
                              //  проверяем на тухлость токен
                        if(error.response.status === 401) { // || error.response.status === 403

                            dispatch('refreshToken', param) /// пробуем получить новый токен

                        }else{
                          //reject(error.response.data.error)
                          reject(error)
                          alert(error.message);
                          //alert(JSON.stringify(error));
                        }

                          if(param.loading) {
                              commit('UpdateGlobaLoading', false)
                          }

                        //alert(JSON.stringify(error))
                  });
            })
          }
      },


      scrollToDown() {
        //alert('ddd')
        const objDiv = this.$refs.cha
        objDiv.scrollTop = objDiv.scrollHeight
      },
          /////////////////////////////////////////////////////////////////////////////////////////////////

          /// это метод если протух токен делаем повторный запрос
          async refreshToken({ commit, state, dispatch }, param){


            await axios.post(state.urlPathAuth + '/refresh', {
              dids: '0'
            }, { headers: { 'Authorization': 'Bearer ' + state.UserData.access_token }})
            .then(response => {

              localStorage.removeItem('UserData');
              commit('UpdateUserData', response.data);
              localStorage.setItem('UserData',  JSON.stringify(response.data))

                // setTimeout(()=>{
                //   console.log('timeout');
                // },1000);
               // alert(param.url)

              dispatch('axiosData', param)
              
              commit('UpdateGlobaLoading', false)

              //alert('Обновил токен')

            })
            .catch(error => {

              commit('UpdateUserData', []);
              localStorage.removeItem('UserData');
              commit('UpdateGlobaLoading', false);
              commit('UpdateExitApp', true);

             window.location.href = '/login'

              //Vue.prototype.$eventBus.$emit('exitApp');
            //  Vue.prototype.$navigateTo(Login, {
            //   clearHistory: true
            // })
            alert('Кончилось время\nВойдите на сайт заново\n\n'+ error)
              //.then(function () {
                commit('UpdateExitApp', true);
                //console.log("Dialog closed!");

                
                
              //  this.$navigateTo(LoginView, {
              //    clearHistory: true
              //  })
            //  });
            


              //throw 0
            });
          },
      


    /// свежий метод get
    ////////////////////////////////////////////////////////////////////////////////////////////////
    // getData({ state, commit }, param){

    //   console.log(param);

    //   if(param.loading) {
    //     commit('UpdateGlobaLoading', true);
    //   }

    
    //   return new Promise((resolve, reject) => {

    //     const p = (param.postData != '') ? '?' + new URLSearchParams(param.postData).toString() : '';

    //     axios
    //         .get(state.urlPath + '/' + param.url + p, { headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Bearer ' + state.UserData.access_token }
    //         })
    //         .then(response => {
    //           resolve(response)
    //           //commit(param.update, response.data);
    //           //console.log(response.data);


    //           if(response.data.error){
    //             if(param.update) {
    //               commit(param.update, []);
    //             }
    //             alert(response.data.error)

    //           }else if(response.data.success){

    //             if(param.update) {
    //               commit(param.update, response.data);
    //             }
    //             alert(response.data.success)

    //           }else{

    //               if(param.update) {
    //                 commit(param.update, response.data);
    //               }
    //           }

    //           if(param.loading) {
    //             commit('UpdateGlobaLoading', false);
    //           }

    //         })
    //         .catch(error => {
    //           reject(error)

    //           alert(error)
    //           //Vue.prototype.$Error401(error);

    //           if(param.loading) {
    //             commit('UpdateGlobaLoading', false);
    //           }

    //         });
    //   })
    // },
/////////////////////////////////
      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //   postData({ state, commit }, param){

    //     if(param.loading) {
    //         commit('UpdateGlobaLoading', true);
    //     }

    //     console.log(param);


    //     return new Promise((resolve, reject) => {
    //         axios.post(state.urlPath + '/'+ param.url,
    //             param.postData
    //             , { headers: { 'Authorization': 'Bearer ' + state.UserData.access_token }})
    //             .then(response => {

    //                 resolve(response)

    //                // console.log(response.data);

    //                 if(response.data.error){

    //                   alert(response.data.error)

    //                 }else{

    //                     if(param.update) {
    //                         commit(param.update, response.data);
    //                     }

    //                     console.log(response);
    //                     //alert(response.data)
    //                 }

    //                 if(param.loading) {
    //                     commit('UpdateGlobaLoading', false);
    //                 }
    //             })
    //             .catch(error => {


    //                 reject(error.response.data.error)

    //                 if(param.loading) {
    //                     commit('UpdateGlobaLoading', false)
    //                 }
    //                 if(error.response.data.errors){
    //                     //alert(error)
    //                     alert(JSON.stringify(error.response.data.errors))
    //                 }else{
    //                   alert(error)
    //                 }



    //                 //alert(error.response.data.errors)

    //             });
    //     })

    // },
////////////////////////////////////////////////////////////////

    /////////////////////////////////////////////////////////////////////////////////////////////////
    // async getUsers({ commit, state }, id = 0){

    //   commit('UpdateGlobaLoading', true);
    //   commit('UpdateLoadingTable', true);

    //   await axios //state.urlPathAuth +
    //   .get(state.urlPathAuth +'/getusers?id='+id, { headers: { 'Authorization': 'Bearer ' + state.UserData.access_token }})
    //   .then(response => {
    //     commit('UpdateUsersList', response.data);
    //     commit('UpdateGlobaLoading', false);
    //     commit('UpdateLoadingTable', false);
    //   })
    //   .catch(error => {
    //     //console.log(param);
    //     Vue.prototype.$Error401(error);
    //     commit('UpdateGlobaLoading', false);
    //     commit('UpdateLoadingTable', false);
    //     //throw error.response.status;
    //   });
    // },
    // getRoleData({ commit, state }){
    //   axios
    //   .get(state.urlPathAuth + '/getroledata', { headers: { 'Authorization': 'Bearer ' + state.UserData.access_token }})
    //   .then(response => {
    //     commit('UpdateRoleData', response.data);
    //     console.log(response.data);
    //   })
    //   .catch(error => {
    //     Vue.prototype.$Error401(error);
    //     //console.log(error);
    //   });
    // },
////////////////////////////////////////////////////////////////////////////////////////////////
    // getPermDepartments({ state, commit }){
    //   axios
    //   .get(state.urlPathAuth + '/getdepartments', { headers: { 'Authorization': 'Bearer ' + state.UserData.access_token }})
    //   .then(response => {
    //     commit('UpdatePermDepartments', response.data);
    //     //console.log(response.data);
    //   })
    //   .catch(error => {
    //     //console.log(error);
    //     Vue.prototype.$Error401(error);
    //   });
    // },
    ////////////////////////////////////////////////////////////////////////////////////////////////
        // getServices({ state, commit }){
        //   axios
        //   .get(state.urlPathAuth + '/getservices?idselectuser='+state.DialogUserPermission.id, { headers: { 'Authorization': 'Bearer ' + state.UserData.access_token }})
        //   .then(response => {
        //     commit('UpdateServiceList', response.data);
        //     console.log(response.data);
        //   })
        //   .catch(error => {
        //     Vue.prototype.$Error401(error);
        //     //console.log(error);
        //   });
        // },

    /////////////////////////////////////////////////////////////////////////////////////////////////
    // async refreshToken({ commit, state, dispatch }){

    //   await axios.post(state.urlPathAuth + '/refresh', {
    //     dids: '0'
    //     }, { headers: { 'Authorization': 'Bearer ' + state.UserData.access_token }})
    //     .then(response => {

    //         localStorage.removeItem('UserData');
    //         //commit('UpdateUserData', response.data);
    //         localStorage.setItem('UserData',  JSON.stringify(response.data));
    //         //alert('update token');
    //         //console.log(response.data);
    //         //console.log(state.UserData);
    //         /// получаем список
    //         dispatch('getUsers',1);

    //     })
    //     .catch(error => {
    //     //commit('UpdateUserData');
    //       localStorage.removeItem('UserData');
    //       commit('UpdateGlobaLoading', false);
    //       alert('Не получилось обновить токен\nВыйдите из приложения\n'+ error); //.response.data.message_ru
    //         // this.$navigateTo(Login, {
    //         //   clearHistory: true
    //         // })
    //     });
    //   },
      ///////////////////////////////////////////////////////////////////////////////////////
      // getRoles({ commit, state }){
      //   axios
      //   .get(state.urlPathAuth + '/getroles', { headers: { 'Authorization': 'Bearer ' + state.UserData.access_token }})
      //   .then(response => {
      //     commit('UpdateRoleList', response.data);
      //     //console.log(response.data);
      //   });
      // },
      // getDictPermitForCurrentUser({ commit, state }){
      //   axios
      //   .get(state.urlPathAuth + '/getdictpermitforcurrentuser', { headers: { 'Authorization': 'Bearer ' + state.UserData.access_token }})
      //   .then(response => {
      //     commit('UpdateDictPermitForCurrentUser', response.data);
      //     //dispatch('getServices');
      //     //console.log(response.data);
      //   });
      // },
      // getclntDictPermitForCurrentUser({ commit, state }){
      //   axios
      //   .get(state.urlPathAuth + '/getrolesforselectuser', { headers: { 'Authorization': 'Bearer ' + state.UserData.access_token }})
      //   .then(response => {
      //     commit('UpdateRolesForSelectUserList', response.data);
      //     //dispatch('getServices');
      //     //console.log(response.data);
      //   });
      // },
      me({ commit, state }){
        axios
        .get(state.urlPathAuth + '/me', { headers: { 'Authorization': 'Bearer ' + state.UserData.access_token }})
        .then(response => {
          commit('UserData', response.data);
          //dispatch('getServices');
          //console.log(response.data);
        });
      },

      // getLog({ commit, state }, param){

      //   commit('UpdateGlobaLoading', true);

      //  axios.post(state.urlPathAuth + '/getlog', {
      //     rolemain: param.rolemain,
      //     limit: param.limit,
      //     date1: param.date1,
      //     date2: param.date2,
      //     loginLog: param.loginLog,
      //     }, { headers: { 'Authorization': 'Bearer ' + state.UserData.access_token }})
      // // getLog({ commit, state }, param){
      // //   commit('UpdateGlobaLoading', true);
      // //   axios
      // //   .get(state.urlPathAuth + '/getlog?rolemain='+param.rolemain+'&limit='+param.limit, { headers: { 'Authorization': 'Bearer ' + state.UserData.access_token }})

      //   .then(response => {
      //     if(!response.data.error) {
      //       commit('UpdateGetLog', response.data);
      //     }else{
      //       commit('UpdateGetLog', []);
      //       //alert(response.data.error)
      //     }
      //     commit('UpdateGlobaLoading', false);
      //     //dispatch('getServices');
      //     //console.log(response.data);
      //   })
      //   .catch(error => {
      //     Vue.prototype.$Error401(error);
      //    commit('UpdateGlobaLoading', false);
      //   });
      // },

  },
  modules: {
  }
})
