

<template>

  <v-container style="max-width: 1000px;">

    <NewMessage/>

    <!-- <Geolocation/> -->

  <v-card
  >
    <v-responsive >

      <span v-if="(this.UserData.user.rolemain===1 && new Date().toJSON().slice(0, 10)=='2024-03-06') || (new Date().toJSON().slice(0, 10)=='2024-03-07' || new Date().toJSON().slice(0, 10)=='2024-03-08' || new Date().toJSON().slice(0, 10)=='2024-03-09' || new Date().toJSON().slice(0, 10)=='2024-03-10')">
        <CongratulateWomen/>
      </span>
      <v-card-title class="text-h5 font-weight-regular primary white--text pa-2">
        <v-icon
        dark
        >
          mdi-home-group
        </v-icon>
       &nbsp;
        Список сервисов
      </v-card-title>

      <v-card-text>

        
        <span
        v-if="!GlobaLoading & ListService.length === 0"
        class="ma-1"
        >
        <v-alert
          outlined
          type="warning"
          prominent
          border="left"
        >
         У вас нет доступа к сервисам
         <v-icon color="warning">mdi-emoticon-sad-outline</v-icon>
        </v-alert>
      </span>

      <br>

        <p
        v-if="GlobaLoading"
        class="pa-3 text-center"
        >
        Подождите загружается список...
      </p>

     


        <v-row v-else>

          <v-col
          v-for="item of ListService" v-bind:key="item.id"
          cols="12"
          sm="6"
          md="4"
          class="pa-1"
          >
         
          <v-card
            max-width="100%"
            outlined
            :href="(item.accept === 1) ? item.url : ''"
            :style="{ opacity: (item.accept === 1) ? 1 : 0.5 }"
          >
            <v-list-item three-line>

              <v-list-item-content v-if="item.accept === 1">
                <v-list-item-title class="text-h7 mb-1">
                  {{ item.publicname }}
                </v-list-item-title>
                <v-list-item-subtitle>{{ item.comment }}</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-content v-else>
                <v-list-item-title class="text-h7 mb-1">
                   Этот сервис недоступен 
                </v-list-item-title>
                <v-list-item-subtitle>только с компьютера подразделения или wi-fi сети</v-list-item-subtitle>
              </v-list-item-content>

              <v-avatar
                size="64"
                rounded
                :color="item.color"
                >
                <v-icon
                x-large
                dark
                >
              {{ item.logo }}
              </v-icon>
                 <!-- <img
                 class="pa-1"
                 dark
                   :src="item.logo"
                 > -->
               </v-avatar>

            </v-list-item>

            <v-card-actions>

            </v-card-actions>
          </v-card>

          

          </v-col>
          


        </v-row>

        

      </v-card-text>
    </v-responsive>
  </v-card>

  <br>
  <span v-if="ShowDeadUsers">
      <DeadUsers/>
    </span>
<!-- 
  <MyComponent/> -->
  </v-container>

</template>


<script>
import NewMessage from '@/components/Message/NewMessage.vue';
import { mapState, mapActions, mapMutations } from 'vuex';
//import Geolocation from '../components/vGeolocation';
// import externalComponent from 'vue-external-component';
//import DeadUsers from '@/components/DeadUsers.vue';

  export default {

    data(){
      return {
        loading: false,
        ShowDeadUsers: false
      }
    },

    components:{
      'DeadUsers': () => import('@/components/DeadUsers.vue'),
      'CongratulateWomen': () => import('@/components/CongratulateWomen.vue'),
    NewMessage
},
    computed: {
         ...mapState([
          'UserData',
          'urlPathAuth',
          'ListService',
          'GlobaLoading'

        ])
    },
    methods:{
      ...mapMutations([
        'UpdateGlobaLoading',
      ]),
      ...mapActions([
        'getServices'
      ]),

      pushService(){
        alert('11');
      }
    },

    mounted(){


      /// если директор или админ выводим инфо о призраках юзерах
      if(this.UserData.user.rolemain === 1 ||this.UserData.user.rolemain === 2 ){

        this.ShowDeadUsers = true

        let json = {
          'pathUrl': this.$store.state.urlPathAuth,  /// путь
          'method': 'get',
          'alert': false, 
          'url': 'deadusers',
          'loading': false,
          //'update': 'UpdateListService',
          'postData': {}
        }
        this.$store.dispatch('axiosData', json)

      }
          /// определяем координаты
      // this.$getLocation()
      //   .then(coordinates => {
      //   console.log('lat ' + coordinates.lat);
      //   console.log('lng '+coordinates.lng);

      //   let json = {
      //     'method': 'get',
      //     'alert': false, 
      //     'url': 'geo',
      //     'loading': false,
      //     //'update': 'UpdateListService',
      //     'postData': {
      //       'lat': coordinates.lat,
      //       'lng': coordinates.lng
      //     }
      //   }
      //   this.$store.dispatch('axiosData', json)

      // })
      // .catch(() => {
      //   this.coordinates = true
      // })

      /// если замена пароля нужна
      if(this.UserData.user.needChangePassword === 1){
        //alert('Вам необходимо установить пароль')

        this.$confirm(
          {
            title: 'Внимание 🤚',
            message: 'Вам необходимо установить пароль',
            button: {
              yes: 'OK'
            },
          })
        //this.$router.push('/user/'+this.UserData.user.login)
        this.$router.push('/changepass')
      }


      //this.loading = true;
      //this.UpdateGlobaLoading(true);

   

      let json = {
        'pathUrl': this.$store.state.urlPathAuth,  /// путь
        'method': 'get',
        'alert': false, 
        'url': 'getserviceslistmain',
        'loading': true,
        'update': 'UpdateListService',
        'postData': {}
      }
      this.$store.dispatch('axiosData', json)
        // .then(() => {
        //   this.loading = false
        // })

        //this.loading = false
        // .catch(() => {
        //   this.loading = false     
        // });
          // await axios
          //   .get(this.urlPathAuth + '/getserviceslistmain', { headers: { 'Authorization': 'Bearer ' + this.UserData.access_token }})
          //   .then(response => {
          //     this.listServise = response.data
          //     //console.log(response.data);
          //   })
          //   .catch(error => {
          //     this.$Error401(error);
          //   });

     
      //this.UpdateGlobaLoading(false);

    }
  }
</script>


